@import '../variables';

.page-template-faq {
  .faq {
    border-radius: 30px;
    padding: 30px;
    background: white;

    .faq-item {
      border-bottom: 2px solid $grey;

      .faq-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @apply py-8;

        .faq-title {
          @apply text-can-blue font-semibold;
          font-size: 24px;
          line-height: 26px;
        }

        .faq-switch {
          cursor: pointer;
          font-size: 1.2em;
          @apply text-can-orange;

          .extend {
            display: block;
          }

          .minify {
            display: none;
          }
        }
      }

      &.active {
        .faq-switch {
          .extend {
            display: none;
          }

          .minify {
            display: block;
          }
        }
      }

      .faq-content {
        p {
          font-size: 14px;
          line-height: 19px;
        }

        //margin-top: 10px;
      }

      &:not(.active) {
        .faq-content {
          display: none;
        }
      }
    }
  }
}