@import '../../variables';

.usps {
  margin-top: 30px;

  .usp {
    display: flex;
    flex-direction: column;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        @apply text-can-orange;
        font-size: 10rem;
      }
    }

    .title {
      margin-top: 15px;
      opacity: .4;
      @apply text-center font-semibold;
    }
  }
}