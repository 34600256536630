$orange: #F5A11C;
$blue: #0090BF;
$lightBlue: #CEE7EF;
$grey: #D9D7D7;

$borderRadius: 21px;

@mixin Button() {

}

@mixin DropdownHeader($backgroundColor, $borderColor, $textColor) {
  .menu-item-has-children  {
    > a {
      &:after {
        margin-left: 10px;
        content: '\f078';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 700;
        font-size: 12px;
      }
    }

    display: inline-block;
    position: relative;

    &:hover {
      .sub-menu {
        transition: all .2s ease-in-out;
        visibility: visible;
        opacity: 1;
        pointer-events: all;
      }
    }

    .sub-menu {
      pointer-events: all;
      visibility: hidden;
      opacity: 0;
      z-index: 1111;
      position: absolute;

      width: max-content;
      max-width: 200px;

      bottom: 0;
      left: -15px;
      transform: translateY(100%);

      height: auto;
      transition: all .2s ease-in-out;
      display: block !important;
      padding: 15px;

      text-align: left;
      background-color: $backgroundColor;

      a {
        color: rgba(0,0,0,0.4);
        padding: 5px;
        text-transform: none;

        @apply hover:text-can-blue;

      }

      .current_page_item {
        a {
          @apply text-can-blue;
        }

        &:after {
          display: none;
        }
      }

      li, li a {
        display: block !important;
        border: none !important;
      }
    }
  }
}