.archive_medewerker_item {
  @apply bg-white rounded-2xl;

  position: relative;
  padding: 10px;

  .img {
    height: 360px;
    @apply rounded-2xl;

    img {
      @apply rounded-2xl;
      width: 100%;
      height: 360px;
      object-fit: cover;
      object-position: top;
    }
  }

  &.just-image {
    img {
      height: 500px;
    }
  }

  .info {
    margin-top: 15px;
    text-align: center;

    .name {
      @apply font-semibold;
      font-size: 20px;
      line-height: 22px;
    }
  }
}