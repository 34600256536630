.content_blocks {

  .content-block {

    &.content-block-text-with-image {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;

        min-height: 300px;
        max-height: 400px;

        border-radius: 30px;
      }
    }

    &.content-block-video {
      iframe {
        border-radius: 30px;
        height: auto;
        width: 100%;
        aspect-ratio: 16/9;
      }

      .video-trigger {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 111;


      }
    }

    &.content-block-images {
      img {
        max-height: 300px;
      }
    }
  }

}