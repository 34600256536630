@import 'variables';

@import 'font';

img.alignright { float: right; margin: 0 0 1em 1em; }
img.alignleft { float: left; margin: 0 1em 1em 0; }
img.aligncenter { display: block; margin-left: auto; margin-right: auto; }
.alignright { float: right; }
.alignleft { float: left; }
.aligncenter { display: block; margin-left: auto; margin-right: auto; }

body {
  position: relative;

  font-size: 17px;
  line-height: 24px;

  color: #333;
  background-color: #F5F5F5;

  min-height: 100vh;


  max-width: 100vw;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;

  &.menu-open {
    position: fixed;
    height: 100vh;
    width: 100vw;
  }

  > main {
    flex: 1;
  }
}

h1 {
  @apply text-can-blue font-semibold;
  font-size: clamp(25px, 85px, 3vw);
  line-height: clamp(30px, 90px, 3.5vw);
}

h2 {
  @apply text-can-blue font-semibold;

  font-size: clamp(22px, 50px, 2.5vw);
  line-height: clamp(30px, 60px, 3.5vw);
}

h3 {
  @apply text-can-blue font-semibold;

  font-size: clamp(20px, 30px, 2.5vw);
  line-height: clamp(25px, 35px, 3vw);
}

p {
  @apply font-light;
}

.body-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  background-image: url('../images/background.png');
  background-image: url('../images/background.svg'), linear-gradient(transparent, transparent);

  background-repeat: repeat-y;
  background-position: top;
}

a {
  text-decoration: none;
  @apply text-can-blue;
  &:hover {
    text-decoration: underline;
  }
}

.the_content {
  ul {
    margin-left: 3vw;
    list-style-type: circle;
    li{
      font-weight: 300;
    }
  }
  a {
    text-decoration: none;
    @apply text-can-blue;
    &:hover {
      text-decoration: underline;
    }
  }
}

.round-img {
  border-radius: 50%;
  aspect-ratio: 1/1;
  object-fit: cover;
}



.btn, [type="submit"] {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 21px;
  transition: all .2s;

  @apply font-semibold;

  font-size: 17px;
  line-height: 17px;

  &.btn-orange, &.gform_button {
    border: 2px solid $orange;
    background: $orange;
    color: white;

    &:hover {
      color: $orange;
      background: transparent;
    }
  }

  &.btn-orange-outline {
    border: 2px solid $orange;
    background: transparent;
    color: $orange;

    &:hover {
      color: white;
      background: $orange;
    }
  }

  &.btn-blue {
    border: 2px solid $blue;
    background: $blue;
    color: white;

    &:hover {
      color: $blue;
      background: white;
    }
  }

  &.btn-blue-outline {
    border: 2px solid $blue;
    background: white;
    color: $blue;

    &:hover {
      color: white;
      background: $blue;
    }
  }
}

.the_content {
  p {
    @apply font-light;
    margin-bottom: 15px;
  }

  h1, h2 {
    @apply font-semibold text-can-blue;
    font-size: clamp(25px, 50px, 3vw);
    line-height: clamp(22px, 45px, 3vw);
    margin-bottom: 15px;
  }
  h3, h4, h5, h6 {
    @apply font-semibold text-can-blue;
    font-size: clamp(25px, 30px, 3vw);
    line-height: clamp(25px, 22px, 3vw);
    margin-bottom: 15px;
    overflow-wrap: break-word;
  }
}

.stretched-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rounded-image {
  border-radius: 30px;
}

.block-hover-shadow {
  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(1.01);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  }
}

.main-header {
  @include DropdownHeader(white, white, black);
}

.block-space {
  @apply mb-24 md:mb-44;
}

.form-container {
  .gform_footer {
    display: flex;
    justify-content: flex-end;
  }

  .gfield {
    &:active, &:focus, &:focus-within {
      label {
        color: $orange !important;
      }
      input,select,textarea {
        border-color: $orange !important;
      }
    }

    label {
      font-weight: 400 !important;
      color: $grey;
      font-size: 15px;
      line-height: 24px;
    }

    input,select,textarea {
      border: 2px solid $grey;
      border-radius: 10px;

      &:active,&:focus {
        outline: none;
      }
    }
  }
}

.has-video {
  position: relative;

  iframe {
    border-radius: 30px;
    height: auto;
    width: 100%;
    aspect-ratio: 16/9;
  }
}

.video-trigger-container {
  position: relative;

  .video-trigger {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 111;
  }
}



@import 'components';
