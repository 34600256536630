.vacature_item {
  height: 100%;
  width: 100%;

  .inner {
    overflow:hidden;
    border-radius: 30px;
    position: relative;

    > a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .content {
    position: relative;
    min-height: 200px;
    background: white;
    border-radius: 30px;
    padding: 30px;

    display: flex;
    flex-direction: column;


    .title-container {
      flex: 1;

      .title {
        color: black;
        font-size: clamp(22px, 33px, 5vw);
        line-height: clamp(25px, 35px, 5vw);
        @apply font-semibold;
      }
    }

    .info {
      margin-top: 20px;

      @apply grid grid-cols-2 gap-4;

      i {
        @apply text-can-orange;
      }
    }
  }

  &.hasImage {
    img {
      height: 250px;
      width: 100%;
      object-fit: cover;
      border-radius: 30px 30px 0 0;
    }

    .content {
      border-radius: 0 0 30px 30px;
    }
  }
}