.title_header {
  .content {
    width: 100%;
    display: flex;
    justify-content: center;

    padding: clamp(5vw, 120px, 12vw) 0;

    h1 {
      max-width: 1000px;
      text-align:center;
    }
  }
}