@import '../variables';

@keyframes blobPulse {
  0% {
    background: linear-gradient(160deg, rgba($blue,0.46) 0%, rgba($blue,1) 50%);
  }
  50% {
    background: linear-gradient(160deg, rgba($blue,0.46) 0%, rgba($blue,1) 100%);
  }
  100% {
    background: linear-gradient(160deg, rgba($blue,0.46) 0%, rgba($blue,1) 50%);
  }
}

.home {
  .header-content {
    .content {
      @apply bg-white pt-24 md:pt-36 -mt-12 rounded-b-2xl px-12 pb-12;

      .the_content {
        max-width: 500px;
      }
    }

    .background-blob {
      aspect-ratio: 1/1;
      border-radius: 50%;
      position: absolute;

      &.orange {
        @apply bg-can-orange;
        width: 50%;
        right: 0;
        top: 0;

        transform: translate(20%, -20%);
        animation: ease-in-out orange-blob-animation 5s infinite;
      }

      @keyframes orange-blob-animation {
        from {
          transform: translate(20%, -20%) scale(1);
        }

        50% {
          transform: translate(20%, -20%) scale(0.95);
        }

        to {
          transform: translate(20%, -20%) scale(1);
        }
      }

      &.blue {
        background: $blue;
        background: linear-gradient(160deg, rgba($blue,0.46) 0%, rgba($blue,1) 50%);

        width: 80%;
        right: 0;
        bottom: 0;

        transform: translate(40%, 0);
        animation: ease-in-out blue-blob-animation 5s infinite;
      }

      @keyframes blue-blob-animation {
        from {
          transform: translate(40%, 0) scale(0.95);
        }

        50% {
          transform: translate(40%, 0) scale(1);
        }

        to {
          transform: translate(40%, 0) scale(0.95);
        }
      }
    }
  }

  .onze_fans {
    .fans {
      margin-top: 30px;
      @apply grid grid-cols-2 md:grid-cols-4 gap-24 md:gap-36;

      .fan {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          object-fit: contain;
          filter: grayscale(100%);
          max-width: 200px;
          transition: all .2s;

          &:hover {
            filter: grayscale(0);
          }
        }
      }
    }
  }

  .teamplayers {
    .players {

      .splide-footer {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .splide__arrow {
          margin-left: 10px;
          color: $orange;
          font-size: 1.3em;
          cursor: pointer;
        }
      }
    }
  }
}