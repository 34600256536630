.archive_blog_item {
  position: relative;

  &:not(:last-of-type) {
    .compact {
     border-bottom: 3px solid #E7E7E7;
    }
  }

  .post-link {
    position: absolute;
    z-index: 111;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


  .date {
    font-size: 15px;
    color: black;
    opacity: .4;
  }

  .compact {
    display: flex;
    justify-content: space-between;

    padding-top: 13px;
    padding-bottom: 10px;

    align-items: center;

    div:first-of-type {
      margin-right: 10px;
    }

    h4 {
      font-size: 20px;
      line-height: 22px;
      @apply font-semibold text-black;
    }

    img {
      height: 99px;
      width: 99px;
      min-width: 99px;
      border-radius: 15px;
      object-fit: cover;
    }
  }

  .normal {
    img {
      height: 259px;
      width: 100%;
      object-fit: cover;
      border-radius: 30px;
    }
    .info {
      margin-top: 10px;

      .title {
        @apply font-semibold text-black;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 5px;
      }

      .the_content {
        font-size: 15px;
        @apply font-light;
      }
    }
  }
 }