.page-single-vacatures {
  .vacature-content {
    border-radius: 30px;
    background: white;
    padding: 30px;

    h2,h3,h4,h5,h6 {
      @apply text-can-blue;
    }

    .info {
      div {
        margin-bottom: 10px;

        i {
          @apply text-can-orange;
        }
      }
    }

    .archive_medewerker_item {
      box-shadow: 5px 5px 15px rgba(black, .05);
    }
  }
}