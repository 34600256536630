.page-template-contact {
  .contact-form {
    position: relative;
    border-radius: 30px;

    .form-container {
      background: white;
      border-radius: 30px;
    }

    .gfield_required {
      @apply text-can-orange;
    }

    .background {
      @apply hidden lg:block;

      width: 80%;
      background: white;
      border-radius: 30px;
      top:0;
      left: 0;
      position: absolute;
      height: 100%;
      z-index: -1;
    }
  }

  .contact-info {
    @apply p-20 bg-can-blue-light;
    border-radius: 30px;
    margin-top:30px;
    margin-bottom:30px;

    .icons {
      li {
        display: flex;
        align-items: center;
        margin-top: 44px;

        @apply font-semibold text-can-blue hover:text-black;


        i {
          margin-right: 15px;
          @apply text-can-orange;
          font-size: 1.4em;
        }
      }
    }
  }
}