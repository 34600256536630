.page-single-blogs {
  margin-top: 75px;

  .date {
    font-weight: 500;
    font-size: 17px;
    line-height: 1.5;
    color: rgba(black , .4);
    margin-bottom: 16px;
    display: inline-block;
  }

  .title {
    @apply text-can-blue font-semibold;
    font-size: clamp(25px, 50px, 3vw);
    line-height: clamp(30px, 60px, 4vw);
    margin-bottom: 16px;
  }

  h2 {
    font-size: clamp(25px, 35px, 3vw);
    line-height: clamp(22px, 40px, 3vw);
  }

  .medewerker {
    display: flex;
    align-items: center;

    .img {
      margin-right: 15px;
      height: 55px;
      width: 55px;
      border-radius: 50%;

      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .info {
      .name {
        font-size: 20px;
        line-height: 15px;
        color: black;
        @apply font-semibold;
      }
      .function {
        font-weight: normal;
        font-size:15px;
        line-height: 15px;
      }
    }
  }

  .thumbnail {
    width:100%;
    height:100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 30px;
  }
}

.content-block.smaller-text {
  display: flex;
  justify-content: center;
  >.inner {
    max-width: 80vw;
  }
}