@import '../../_variables.scss';

header {
  &.sticky-header {
    z-index: 9999;

    &[data-has-scrolled="true"] {
      .content {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      }
    }

    position: sticky;
    top: 10px;

    .content {
      padding: 5px 15px;
      transition: box-shadow .3s;

      @apply bg-white rounded-2xl;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ul {
      display: flex;

      .current-menu-item, .current_page_parent {
        a {
          @apply text-can-blue;
        }

        position: relative;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          @apply bg-can-orange;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          max-width: 30px;
          height: 3px;
          border-radius: 3px;
        }
      }

      li:not(:last-of-type) {
        margin-right: 3rem;
      }

      li {
        @apply font-semibold;

        > a {
          color: rgba(black, .4);

          &:hover {
            color: $blue;
          }

          &:after {
            @apply text-can-orange;
          }
        }
      }
    }

    @include DropdownHeader(white, #ececec, black);
  }


  .menu-toggle {
    width: 60px;
    height: 45px;
    position: relative;
    //margin: 50px auto;
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 9px;
      width: 100%;
      background: $blue;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 0px;
        transform-origin: left center;
      }

      &:nth-child(2) {
        top: 18px;
        transform-origin: left center;
      }

      &:nth-child(3) {
        top: 36px;
        transform-origin: left center;
      }
    }

    &.active {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: -3px;
          left: 8px;
        }
        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 39px;
          left: 8px;
        }
      }
    }
  }
}

.top-info {
  padding: 10px 15px;

  .top-info-items {
    @apply flex md:flex-row flex-col;
  }

  a {
    margin: 0 15px;
    @apply text-can-blue font-semibold;

    i {
      margin-right: 5px;
      @apply text-can-orange;
    }
  }
}

.mobile-navigation {
  z-index: 9998;
  max-width: 100vw;
  display: none;

  overflow-y: scroll;

  &.active {
    display: block;
  }

  .current_page_item, .current_page_ancestor {
    > a {
      color: $orange !important;
    }
  }

  background: #F5F5F5;

  position: fixed;
  padding-top: 100px;

  top: 0;
  left:0;
  width:100%;
  height: 100%;

  ul.menu {

    > li {
       > a {
         color: $blue;
         font-size: 24px;
         line-height: 48px;
       }
    }

    .sub-menu {
      display: none;

      &.active {
        display: block;
      }
    }

    .menu-item-has-children {
      > a {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}