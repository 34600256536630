.video_popup {
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;

  z-index: 99999;

  display: none;

  &.active {
    display: block;
  }

  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: rgba(#000, .2);
  }

  .inner {
    position: relative;
    z-index: 111;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    pointer-events: none;

    .content {
      pointer-events: all;

      margin: 30px;
      width: 100%;
      max-width: 800px;

      aspect-ratio: 16/9;

      max-height: 100%;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

}