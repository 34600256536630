@import '../variables';

.page-single-medewerkers {
  h1 {
    font-size: 55px;
    line-height: 60px;
  }

  .contact-info {
    @apply py-8;

    .contact-info-item {
      font-size:17px;
      line-height: 20px;
      margin-bottom: 20px;
      i {
        @apply text-can-orange;
        margin-right: 20px;
      }

      a {
        @apply text-black hover:text-can-blue;
      }
    }
  }
}