@import '../../variables';

footer {
  a {
    position: relative;
  }

  .content {
    position: relative;
    overflow: hidden;
    @apply bg-white pt-24;

    border-radius: 30px 30px 0 0 ;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 125px;
    }
  }

  ul {
    list-style: none;

    &.light {
      li {
        @apply hover:text-can-blue;
        opacity: .4;
        transition: opacity .2s ease-in-out;
        &:hover {
          opacity: 1;
        }
      }
    }

    li {
      &.title {
        @apply text-can-blue font-semibold;
      }

      a {
        @apply hover:text-can-blue;
      }
    }
  }


  .background-blob {
    aspect-ratio: 1/1;
    border-radius: 50%;
    position: absolute;

    &.orange {
      opacity: .75;
      @apply bg-can-orange;
      width: 25%;
      right: 0;
      top: 0;

      transform: translate(20%, -50%);
      animation: ease-in-out orange-blob-animation-2 5s infinite;
    }

    @keyframes orange-blob-animation-2 {
      from {
        transform: translate(20%, -50%) scale(1);
      }

      50% {
        transform: translate(20%, -50%) scale(0.95);
      }

      to {
        transform: translate(20%, -50%) scale(1);
      }
    }

    &.blue {
      background: $blue;
      background: linear-gradient(160deg, rgba($blue,0.46) 0%, rgba($blue,1) 50%);

      width: 30%;
      right: 0;
      bottom: 0;

      transform: translate(20%, 60%);
      animation: ease-in-out blue-blob-animation-2 5s infinite;
    }

    @keyframes blue-blob-animation-2 {
      from {
        transform: translate(20%, 60%) scale(0.95);
      }

      50% {
        transform: translate(20%, 60%) scale(1);
      }

      to {
        transform: translate(20%, 60%) scale(0.95);
      }
    }
  }
}