.teamtalk {
  max-width: 100%;
  overflow-x: hidden;

  h2 {
    margin-bottom: 15px;
  }

  .posts-overview {
    @apply lg:grid grid-cols-3 gap-24;

    .random-two-cols {
      @apply grid md:grid-cols-2 gap-24 col-span-2;
    }
  }
 }